/* import __COLOCATED_TEMPLATE__ from './ping-domain-errors.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import type IntlService from 'ember-intl/services/intl';
import {
  getJwtErrorStampsMap,
  getStampForErrorType,
  type StampFormat,
} from 'embercom/utils/jwt-error-stamps';

interface Args {
  domainName: string;
  errorCount: number;
  logsType: string;
  logsPlatform: string;
}

interface Signature {
  Args: Args;
}

interface PingDomainError {
  request_id: string;
  domain: string;
  referer: string;
  timestamp: string;
  error_name: string;
  error_message: string;
  user_data: string;
  installation_type: string;
  device_identifier: string;
  source: string;
}

export default class PingDomainErrors extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;

  @tracked domainName: string;
  @tracked errorCount: number;
  @tracked logsType: string;
  @tracked logsPlatform: string;
  @tracked isLoading = true;
  @tracked isLoadingMore = false;
  @tracked showRequestModal = false;
  @tracked modalRequestId: string | null = null;

  @tracked pingDomainErrors: PingDomainError[] = [];
  @tracked nextPageToken: string | null = null;
  @tracked stampErrorsMap: Record<string, StampFormat> = {};

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.domainName = args.domainName;
    this.errorCount = args.errorCount;
    this.logsType = args.logsType;
    this.logsPlatform = args.logsPlatform;
    this.stampErrorsMap = getJwtErrorStampsMap(this.intl);
    this.fetchData();
  }

  get app() {
    return this.appService.app;
  }

  get appId() {
    return this.app.id;
  }

  get columns() {
    return [
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.timestamp',
        ),
        valuePath: 'timestamp',
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.error-type',
        ),
        valuePath: 'error_name',
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.source',
        ),
        valuePath: 'source',
      },
      {
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.web.setup-idv.ping-domain-errors.referer',
        ),
        valuePath: 'referer',
      },
      { label: '', type: 'stamp', valuePath: 'request_id' },
    ];
  }

  @action
  async fetchData(): Promise<void> {
    this.isLoading = true;
    if (!this.domainName) {
      console.error('Messenger ping domain error: Domain is not set - unable to fetch data');
      return;
    }

    try {
      let response = await ajax({
        url: '/ember/ping_domain_errors',
        type: 'GET',
        data: {
          app_id: this.appId,
          domain: this.domainName,
          type: this.logsType,
          platform: this.logsPlatform,
        },
      });
      this.pingDomainErrors = response.items;
      this.nextPageToken = response.next_page_token;
    } catch (error) {
      console.error('Error fetching ping domain errors:', error);
    } finally {
      this.isLoading = false;
    }
  }

  @action
  async loadMore(): Promise<void> {
    this.isLoadingMore = true;
    if (!this.nextPageToken) {
      return;
    }

    try {
      let response = await ajax({
        url: '/ember/ping_domain_errors',
        type: 'GET',
        data: {
          app_id: this.appId,
          domain: this.domainName,
          type: this.logsType,
          platform: this.logsPlatform,
          next_page_token: this.nextPageToken,
        },
      });

      this.pingDomainErrors = [...this.pingDomainErrors, ...response.items];
      this.nextPageToken = response.next_page_token;
    } catch (error) {
      console.error('Error loading more ping domain errors:', error);
    } finally {
      this.isLoadingMore = false;
    }
  }

  @action
  stampForErrorType(errorType: string): StampFormat {
    return getStampForErrorType(errorType, this.stampErrorsMap, this.intl);
  }

  @action
  truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
      return text;
    }
    return `${text.slice(0, maxLength)}...`;
  }

  @action
  closeRequestModal() {
    this.showRequestModal = false;
    this.modalRequestId = null;
  }

  @action
  openRequestModal(requestId: string) {
    this.showRequestModal = true;
    this.modalRequestId = requestId;
  }

  @action
  humanizedSource(source: string) {
    if (source === 'apiBoot') {
      return "Intercom('boot')";
    } else if (source === 'apiUpdate') {
      return "Intercom('update')";
    } else {
      return source;
    }
  }

  get canLoadMore() {
    return this.nextPageToken !== null;
  }

  get modalErrorLog() {
    if (!this.modalRequestId) {
      return null;
    }

    return JSON.stringify(
      this.pingDomainErrors.find((error) => error.request_id === this.modalRequestId),
      null,
      2,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::Web::IdvSetup::PingDomainErrors': typeof PingDomainErrors;
    'installation-new/web/idv-setup/ping-domain-errors': typeof PingDomainErrors;
  }
}
